.toggle {
    background-color: #0d6efd;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    cursor: pointer;
    line-height: 1;
  }
  .input {
    display: none;
  }
  
  .icon {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
    transition: transform 500ms;
  }
  
  .icon--moon {
    transition-delay: 200ms;
    color: white;
  }
  
  .icon--sun {
    transform: scale(0);
    color: white;
  }
  
  #switch:checked + .icon--moon {
    transform: rotate(360deg) scale(0);
  }
  
  #switch:checked ~ .icon--sun {
    transition-delay: 200ms;
    transform: scale(1) rotate(360deg);
  }
  