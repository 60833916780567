/* breadcrumbs */
.breadcrumbs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    }
.breadcrumbs >*{
    display: flex;
    display: inline-block;
    margin-right: 10px;
    }
.breadcrumbs .crumb:after {
    content : '>';
    margin-left: 10px;
    }
.breadcrumbs .crumb:last-child:after {
    display: none;
    }
.breadcrumbs .crumb.text-dark a{
    color: black;
    text-decoration: none;
}
.breadcrumbs .crumb.text-light a{
    color: white;
    text-decoration: none;
}